.header {
  overflow: hidden;
  background-color: #f1f1f1;
}

.header p {
  float: left;
  color: black;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  font-size: 18px;
  line-height: 15px;
  border-radius: 4px;
}

.inputField {
  width: 40% !important;
  padding: 1px;
  margin-top: 5px;
  margin-right: 6px;
  border: none;
  font-size: 17px;
}

.header p.logo {
  font-size: 25px;
  font-weight: bold;
  color: #4284f5;
}

.header p:hover {
  background-color: #ddd;
  color: #5d6770;
}

.header p.active {
  background-color: dodgerblue;
  color: white;
}

.header-right {
  float: right;
}

@media screen and (max-width: 500px) {
  .header p {
    float: none;
    display: block;
    text-align: left;
  }

  .header-right {
    float: none;
  }
}
